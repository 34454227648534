  .table_header {
    display: flex;
    justify-content: space-around;
    border-radius: 10px;
    padding: 20px;
    position: sticky;
    top: 3px;
    border: 1px solid #000000;
  }

  .table_body_item {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-around;
    background: #eaeaea7b;
    border-radius: 10px;
    padding: 10px;
    color: #000000;
    margin: 10px 0;
    font-size: 1rem;
  }

  .table_body_item:hover {
    background: #eaeaea !important;
  }

  /* .table_body_item_useCase{
    
  } */
  .table_body_item_text {
    
    height: 200px;
    overflow: auto;
    font-family: Tajawal;
    display: flex;
    align-items: center;

  }
  .trans_heading{
    display: flex;
    flex-direction: column;
  }

  .trans_body{
     display: flex;
    flex-direction: column;
  }
/* 
  .table_body_item_text_h6 {
    color: #000000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 500px;


  } */

  .table_footer_item {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }

  .table_footer_buttons {
    align-items: center;
    display: flex;
    gap: 10px;
  }

  .table_footer_buttons button {
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 10px;
    border: none;
  }

  .table_footer_item select {
    border-radius: 5px;
    padding: 2px 5px;
    height: 50%;
    cursor: pointer;
    border: 1px solid #000000;
  }

  .details_page_stats {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .details_page_stats h4 {
    background: linear-gradient(270deg, #151723, #23273a);
    color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 1rem;
    margin: 5px;
    flex-grow: 1;
    text-align: center;
  }

  .detail_card {
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 1px 0px 8px rgb(128 128 128 / 14%);
    padding: 10px;
    margin: 10px 0;
  }

  .detail_card h6 {
    line-height: 1.3rem;
  }

  @media screen and (max-width: 768px) {
    .detail_card h6 {
      line-height: 2.3rem;
    }
  }

  .code_decoration pre {
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.058);
    padding: 20px 10px;
    border-radius: 10px;
    background: #fff;
  }

  .code_decoration pre div {
    width: 100%;
    background: transparent !important;
    /* color: #fff; */

    direction: ltr;
    text-align: left;
  }

  .code_decoration ol {
    width: 100%;
  }